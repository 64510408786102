
    .course-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .course-header {
            background: #fff;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            position: relative;
            span {
                margin-right: 10px;
                color: #1989FA;
            }
            .timer {
                 color: #FF0000;
                 margin: 0 50px 0 20px;
                span {
                    color: #FF0000;
                    margin-left: 20px;
                }
            }
            .user {
                position: absolute;
                top: 50%;
                right: 40px;
                transform: translateY(-50%);
                .user-info {
                    display: flex;
                    align-items: center;
                    .user-name {
                        font-size: 16px;
                        color: #606266;
                    }
                    .el-icon-arrow-down {
                        color: #606266;
                    }
                }
                .m-userinfo {
                    a {
                        display: block;
                        font-size: 16px;
                        color: #666;
                    }
                }
                .user-avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    border: 1px solid #ddd;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .user .header-el-dropdown{
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 10px 0;
                .user-text {
                    line-height: 40px;
                    margin-left: 10px;
                }
                img {
                    display: block;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                }
            }
        }
        .course-contain {
            height: 1%;
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            & * {
                box-sizing: border-box;
            }
            .iframe-content {
                display: flex;
                flex: 1;
                height: 100%;
            }
        }
    }
    .sign-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .sign-title, .sign-num, .sign-btn {
            margin: 10px 0;
        }
    }
    .blackboard-dialog {
        ::v-deep .el-dialog__body {
            height: calc( 100% - 60px);
            padding: 0 10px;
        }
    }
	.true-section {
		margin-top: 15px;
		box-sizing: border-box;
		& * {
			box-sizing: border-box;
		}
		.true-section_name {
			padding-left: 20px;
			color: #999;
			.true-section_index {
				padding-right: 10px;
			}
		}
	}

    .course-contain-left {
        width: 58px;
        background-color: #1C1F21;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .contain-left-text {
            width: 100%;
            height: 392px;
            display: flex;
            flex-direction: column;
        }
    }



    .left-button {
        height: 56px;
        color: #B3B3B3;
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    .left-button i {
        margin: 9px auto 0;
    }

    .left-button span {
        margin: 0 auto;
    }

    /*start视频播放*/
    .video-play-head {
        height: 100%;
        width: calc(100% - 58px)

    }

    .video-play {
        height: calc(100%);
        background-color: #000000;
        width: 100%;
    }
    /*end视频播放*/

    /*start章节弹窗*/
    .course-chapter-dialog{
        /*width: 400px;*/
        height: 100%;
        background-color: #26292C;
        position: fixed;
        left: 58px;
        z-index: 10;
        overflow: auto;
        color: #ffffff;
    }
    .course-chapter-head{
        height: calc(100% - 80px);
        /*width: 350px;*/
        margin:7px 25px 20px 20px;
		::v-deep .el-scrollbar__wrap {
			overflow-x: hidden;
		}
    }
    .chapter-title{
        padding-top: 23px;
        text-align: left;
        width: 240px;
        color: #FFFFFF;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
    .chapter-title span:last-child{
        margin-left: 5px;
    }
    .section-title{
        width: 360px;
        cursor: pointer;
        padding-top: 16px;
        padding-left: 20px;
        color: #999999;
    }
    .section-title span:nth-child(2){
        margin-left: 8px;
        padding-top: -2px;
    }
    .section-title span:nth-child(3){
        margin-left: 12px;
        width: 210px;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
    .section-title .section-teachering{
        float: right;
        margin-top: 3px;
        margin-right: 10px;
        color: #FF0000;
    }
    .teacherActive{
        float: right;
        margin-top: 3px;
        margin-right: 10px;
        -webkit-text-fill-color: #3296FA;
    }
    .unTeacherActive{
        float: right;
        margin-top: 3px;
        margin-right: 10px;
    }
    .section-teachering i{
        margin-left: 10px;
    }
    /*end章节弹窗*/
    /*start签到弹窗*/
    .course-sign-dialog{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
    }
    .course-sign-dialog .sign-contain {
        width: 450px;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 12;
        background: #fff;
    }
    .sign-contain-head{
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid #EAEAEA;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .sign-contain-head span:first-child{
        margin-left: 32px;
    }
    .sign-contain-head span:last-child{
        margin-right: 16px;
        cursor: pointer;
    }
    .sign-contain-main{
        height: 167px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sign-contain-main p:first-child{
        margin-top: 26px;
    }
    .sign-contain-main .el-button{
        height: 32px;
        width: 100px;
        line-height: 0;
        background-color:#3296FA;
        color: #ffffff;
    }
    /*end签到弹窗*/
    /*Start讨论*/
    .course-talk{
        width: 478px;
        height: 100%;
    }
    .course-talk-head{
        height: 45px;
        line-height: 45px;
        display: flex;
        justify-content: space-between;
    }
    .course-talk-head span:first-child{
        margin-left: 31px;
    }
    .course-talk-head span:last-child{
        margin-right: 28px;
        cursor: pointer;
    }
    .course-talk-main{
        height: calc(100% - 325px);
        overflow: auto;
    }
    .talk-main-name{
        margin-left: 31px;
        display: flex;
        flex-direction: row;
        width: 320px;
        margin-top:15px;
    }

    .talk-main-name img{
        width: 24px;
        height: 24px;
        padding-top: 3px;
    }
    .talk-main-name .name-teacher{
        background-color: #3296FA;
        margin-left: 10px;
        border-radius:2px;
        color: #ffffff;
        padding: 4px 6px 4px 6px;
    }
    .talk-main-name .talk-name{
        margin-left: 15px;
        padding-top: 3px;
        color: #3296FA;
    }
    .talk-context{
        margin-top: 5px;
        margin-left: 30px;
        width: 320px;
        word-wrap:break-word;
        word-break:normal;
    }
    .talk-middle{
        height: 32px;
        line-height: 32px;
        background-color: #EAEAEA;
        padding-left: 30px;
        color: #8e8e8e;
        font-size: 16px;
    }
    .talk-middle i{
        cursor: pointer;
    }
    .talk-middle i:nth-child(2){
        margin-left: 15px;
    }
    .talk-middle i:nth-child(3){
        margin-left: 15px;
    }
    .talk-middle i:nth-child(4){
        margin-left: 15px;
    }
    .talk-space{
        height: 238px;
        overflow: auto;
    }
    .talk-input{
        height: calc(100% - 40px);
        overflow: auto;
    }
    .talk-button{
        margin-right: 20px;
        margin-top: 5px;
        float: right;
    }
    .talk-button .el-button{
        color: #ffffff;
        background: #3296FA;
        height: 35px;
        width: 110px;
        line-height: 0;
    }
    /*End讨论*/
    /*Start实训*/
    .train-content{
        margin-top: 5px;
        margin-left: 30px;
    }
    .train-content span{
        color: #3296FA;
        margin-left: 5px;
    }
    .train-dataTime{
        padding-top: 5px;
        margin-left: 10px;
    }
    .train-title-input{
        margin-top: 23px;
        margin-right: 44px;
    }
    .train-upload-file{
        display: flex;
        flex-direction: row;
    }
    /*End实训*/
    /*start小测*/
    .test-selectPaper{
        float: right;
        margin-right: 20px;
        cursor: pointer;
    }
    .test-input{
        height: calc(100% - 40px);
        overflow: auto;
    }
    /*end小测*/
